import React from 'react'
import { graphql } from "gatsby"
import { Seo }  from "../components/seo";
import { Layout } from "../components/layout";
import GraphQLErrorList from "../components/graphql-error-list";


export default function SuperPage(props) {
  // console.log({props})
  const { data, errors } = props;
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const {page} = data;
  if (!page) {
    throw new Error(
      'Missing page content.'
    );
  }

  return (
     <Layout title={page.title} layout={page._rawFlexibleLayout}>
       <Seo pageURL={`/`} title={page.seoSettings.title} description={page.seoSettings.description} openGraphImage={page.seoSettings?.openGraphImage?.asset?.url ? page.seoSettings?.openGraphImage?.asset?.url : null} datePublished={page._updatedAt} />
    </Layout>
  )
}

export const query = graphql`
  query($id: String) {
    page: sanityPage(id: {eq: $id}) {
      id
      title
      slug {
        current
      }
      _updatedAt
      _rawFlexibleLayout
      seoSettings {
        description
        title
        openGraphImage {
          asset {
            url
          }
        }
      }
    }
  }
`